var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      class: ["c-card mb-5"],
      attrs: { "body-class": "c-card-body", "no-body": "" },
    },
    [
      _c(
        "b-row",
        { staticStyle: { height: "100%" }, attrs: { "align-v": "stretch" } },
        [
          _c(
            "b-col",
            {
              staticClass: "text-banner",
              attrs: { cols: "12", md: "6", "align-self": "center" },
            },
            [
              _c(
                "b-row",
                {
                  staticClass: "p-5",
                  staticStyle: { height: "100%" },
                  attrs: { "align-v": "stretch" },
                },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "h1",
                        {
                          staticClass: "text-default display-3 mb-3 med-font",
                          staticStyle: {
                            "line-height": "1.1",
                            "font-weight": "initial",
                          },
                        },
                        [
                          _vm._v(" Hi "),
                          !_vm.name
                            ? [_vm._v("...")]
                            : [_vm._v(_vm._s(_vm.name))],
                          _vm._v(", we’re so glad you’re here. "),
                        ],
                        2
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            pill: "",
                            variant: "outline-dark",
                            to: "/treatment",
                            size: "lg",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-col", {
            staticClass: "bg-img",
            style: {
              backgroundImage: "url(" + _vm.backgroundImage + ")",
              height: _vm.height,
              "margin-bottom": _vm.marginBottom,
            },
            attrs: { cols: "12", md: "6", "align-self": "stretch" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }