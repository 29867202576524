<template>
  <b-container class="p-0" fluid>
    <template v-if="prescription == 'rx'">
      <b-row>
        <b-col class="bg-info text-center py-2 text-light" v-if="coupon.show">
          {{ coupon.message }}
          <a
            v-if="coupon.showBlossomLink"
            href="javascript:;"
            class="text-light"
            @click="showBlossomModal('BLOSSOM')"
            ><u>Claim now</u>
          </a>
        </b-col>
        <b-col
          class="bg-danger text-center py-2 text-light"
          v-if="couponerror.show"
        >
          {{ couponerror.message }}
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col cols="12" :md="show ? 6 : 9">
          <b-row class="mb-5 hero-banner">
            <b-col v-if="id" cols="12">
              <div
                :class="[
                  'alert alert-danger mb-3 text-center verify-status',
                  !verificationstatus ? 'not-verify' : '',
                ]"
              >
                Action Required. You have something that needs updated or
                confirmed in your account.
                <router-link
                  to="/account-settings"
                  custom
                  v-slot="{ navigate }"
                >
                  <a
                    @click="navigate"
                    href="javascript:void(0)"
                    class="color-inherit text-underline"
                    >Visit here</a
                  ></router-link
                >
                .
              </div>
              <div
                class="alert alert-danger mb-3 text-center verify-status"
                :class="showSkipAlert ? 'not-verify' : ''"
              >
                For our doctor to accept you, please upload your
                {{ iduploaded == "SKIPPED" ? "ID" : "" }}
                {{
                  iduploaded == "SKIPPED" && photouploaded == "SKIPPED"
                    ? "and"
                    : ""
                }}
                {{ photouploaded == "SKIPPED" ? "Personal Photo" : "" }}
                by clicking
                <router-link
                  to="/account-settings"
                  custom
                  v-slot="{ navigate }"
                >
                  <a
                    @click="navigate"
                    href="javascript:void(0)"
                    class="color-inherit text-underline"
                    >here</a
                  ></router-link
                >
              </div>
              <b-alert
                v-if="followups.length > 0 && isTwoWeeksBeforeFollowup"
                style="
                  background: #1a6a72;
                  color: #cdfcb1;
                  border-color: #1a6a72;
                "
                show
                class="mb-5 h3"
              >
                <span v-if="followups[0].type === '1YEAR'">
                  1 Year Follow Up:
                </span>
                <span v-else>10 Week Follow Up: </span>
                Your next check-in is scheduled for
                {{ parseDate(followups[0].followupdatestamp) }}.
              </b-alert>
            </b-col>
            <b-col
              cols="12"
              :lg="show ? 12 : 6"
              :class="show ? 'text-center' : 'pr-md-5'"
            >
              <b-row align-v="stretch" style="height: 100%">
                <b-col cols="12">
                  <div class="mt-5">
                    <h1
                      class="text-primary display-3 mb-3 med-font"
                      style="line-height: 1.1; font-weight: initial"
                    >
                      Hi <template v-if="!name">...</template
                      ><template v-else>{{ name }}</template
                      >, we’re so glad you’re here.
                    </h1>
                    <p class="sub-text mt-5">
                      Here’s what to expect in the next few days. If have any
                      questions, you can always start a consultation with your
                      provider.
                    </p>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  align-self="end"
                  class="mb-4"
                  v-for="(item, i) in availableDashboardUpsell"
                  :key="i"
                >
                  <b-row class="p-4 mx-1 mx-md-0 banner-box">
                    <b-col cols="4" class="pl-0 text-center">
                      <b-img fluid :src="item.images[0]" alt="" />
                    </b-col>
                    <b-col cols="8">
                      <h2 class="mb-3">{{ item.metadata.header }}</h2>
                      <p v-html="item.description.html"></p>
                      <b-button
                        variant="info"
                        size="lg"
                        pill
                        @click="showBlossomModal(item.upsellcode)"
                      >
                        {{ item.reorder ? "Reorder now" : "Order now" }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" :lg="show ? 12 : 6">
              <div class="timeline-box mt-5">
                <h2 class="bold-font h1" style="margin-bottom: 2.5rem">
                  What’s next?
                </h2>
                <ul
                  :class="[
                    'list-unstyled timeline',
                    show ? 'show-all' : 'hide-some',
                  ]"
                >
                  <li
                    v-for="(list, i) in timeline"
                    :key="i"
                    :class="list.done ? 'done' : ''"
                  >
                    <b-icon v-if="list.done" icon="check-circle-fill"></b-icon>
                    <div class="list-content">
                      <h2 class="med-font mb-2">{{ list.title }}</h2>
                      <p class="sub-text mb-1">{{ list.desc }}</p>
                      <span v-if="list.after" class="text-muted small-text">{{
                        list.after
                      }}</span>
                    </div>
                  </li>
                </ul>
                <b-button class="ml-5 mb-4" variant="link" @click="showMore()">
                  <template v-if="show"> Show Less </template>
                  <template v-else> Show More </template>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </template>

    <template v-if="prescription == 'non-rx'">
      <b-row>
        <b-col cols="12">
          <banner
            :name="name"
            button-text="view your order"
            :background-image="require('@/assets/images/login/bg.png')"
            height="calc(100vh - 150px)"
          />
        </b-col>
      </b-row>
    </template>

    <b-row class="p-4">
      <b-col cols="12" lg="4" class="px-4 mb-4">
        <d-card
          bg-color="#E8F2EC"
          border-color="#cdfcb1"
          :image="require('@/assets/images/dashboard/msg-bg.png')"
          link="/messages"
          text="Message Provider"
        />
      </b-col>
      <b-col cols="12" lg="4" class="px-4 mb-4">
        <d-card
          bg-color="#E8F2EC"
          border-color="#cdfcb1"
          :image="require('@/assets/images/dashboard/support-bg.png')"
          url="https://help.bywinona.com/en/"
          text="Support Center"
        />
      </b-col>
      <b-col cols="12" lg="4" class="px-4">
        <b-card
          style="height: 435px; background-color: #94d9c3"
          class="default-bg"
        >
          <b-row align-v="stretch" style="height: 100%">
            <b-col cols="12">
              <img
                :src="require('@/assets/images/dashboard/heart.svg')"
                alt=""
                class="mb-4"
              />
              <h2 class="mb-2 heading text-default">How can we help you?</h2>
              <p class="mb-0 sub-text">
                Have a question about your membership, or trouble accessing your
                account? Your friends at the Winona are here to help!
              </p>
            </b-col>
            <b-col cols="12" align-self="end">
              <h2 class="mb-0 mt-5 pt-4">
                <router-link
                  :to="
                    isUserSupportsAdminPatientChat
                      ? '/messages/admin'
                      : '/contact-us'
                  "
                  class="text-default"
                >
                  Contact Care Team <span class="next-arrow"></span>
                </router-link>
              </h2>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="p-4">
      <b-col cols="12" lg="4" class="px-4 mb-4">
        <d-card
          bg-color="#E8F2EC"
          border-color="#C7BDFF"
          :image="require('@/assets/images/for-facebook-community.png')"
          url="https://www.facebook.com/groups/141206008222366"
          text="Join Winona Womens’ Facebook Community"
        />
      </b-col>
      <b-col cols="12" lg="4" class="px-4 mb-4">
        <d-card
          bg-color="#E8F2EC"
          border-color="#C7BDFF"
          :image="require('@/assets/images/webinar.png')"
          url="https://app.livestorm.co/winona"
          text="Sign Up for Our Monthly Live Q&A"
        />
      </b-col>
      <b-col cols="12" lg="4" class="px-4">
        <b-card
          style="height: 435px; background-color: rgba(97, 211, 226, 0.5)"
          class="default-bg"
        >
          <b-row align-v="stretch" style="height: 100%">
            <b-col cols="12">
              <img
                :src="require('@/assets/images/dashboard/physician.svg')"
                alt="physician"
                class="mb-4"
              />
              <h2 class="mb-2 heading text-default">Winona Physician</h2>
              <p class="mb-0 sub-text">
                For questions regarding your health, treatment, and symptoms
              </p>
            </b-col>
            <b-col cols="12" align-self="end">
              <h2 class="mb-0 mt-5 pt-4">
                <router-link to="/messages/doctor" custom v-slot="{ navigate }">
                  <a
                    href="javascript:void(0)"
                    @click="navigate"
                    class="text-default"
                  >
                    Contact Winona Physician <span class="next-arrow"></span>
                  </a>
                </router-link>
              </h2>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <modal
      id="modalNotif"
      ref="modal"
      size="md"
      centered
      hide-header
      hide-footer
    >
      <div class="modal-header intake-header" style="padding: 0; border: 0">
        <b-container fluid class="pl-0 pr-0">
          <b-row>
            <b-col cols="12" class="text-right">
              <b-button
                size="sm"
                variant="link"
                @click="$bvModal.hide('modalNotif')"
              >
                <b-icon icon="x-circle-fill" variant="primary"></b-icon>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="text-center p-3">
        <h2 class="h1 heading text-primary mb-3">
          Your treatment has been changed
        </h2>
        <p class="sub-text">
          The price of Progesterone Capsule treatment has increased from $89 to
          $199.
        </p>

        <b-button pill variant="info" style="min-width: 200px"
          >View Message</b-button
        >
      </div>
    </modal>

    <OneClickUpsell
      v-if="isUpsellModalVisible"
      :isUpsellModalVisible.sync="isUpsellModalVisible"
      upsell-from="Patient Portal Upsell"
      :couponid="$cookies.get('promocode')"
      @remove-banner="coupon.show = false"
    />
  </b-container>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import moment from "moment";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";

// components
import dCard from "@/components/partials/Card";
import banner from "@/components/partials/Banner";
import OneClickUpsell from "@/components/onboarding/checkout/OneClickUpsell";

// services
import LocalStorageService from "@/localStorage";
const localStorageService = LocalStorageService.getService();
import { PromocodeService } from "@/services/promocode.service";
import { OnboardingService } from "@/services/onboarding.service";

export default {
  name: "dashboard",
  components: {
    // articleCard,
    dCard,
    banner,
    OneClickUpsell,
    // GiftCard,
  },
  data() {
    return {
      name: "",
      id: "",
      loading: true,
      prescription: "rx",
      verificationstatus: false,
      error: false,
      postsUrl: `${this.$store.state.website}/wp-json/wp/v2/posts`,
      queryOptions: {
        categories: null,
        per_page: 2, // Only retrieve the 10 most recent blog posts.
        page: 1, // Current page of the collection.
        _embed: true, //Response should include embedded resources.
      },
      posts: [],
      show: false,
      iduploaded: null,
      photouploaded: null,

      timeline: [
        {
          done: true,
          title: "Your Free Visit",
          desc: "You’ve completed your visit, your doctor will now review",
        },
        {
          done: true,
          title: "Doctor Treatment ",
          desc: "If you’re eligible for treatment, your doctor will write you a prescription and pass it to our pharmacy.",
          after: "1-2 days after signup",
        },
        {
          done: false,
          title: "Prescription Received",
          desc: "Your treatment will be prepared and shipped via fast & free shipping.",
          after: "1-2 days after signup",
        },
        {
          done: false,
          title: "Treatment Shipped",
          desc: "Your treatment is on the way.",
          after: "2-4 days after signup",
        },
        {
          done: false,
          title: "Treatment Delivered",
          desc: "Success! You can now start your treatment.",
          after: "4-7 days after signup",
        },
        {
          done: false,
          title: "Your 10 Week Check-In",
          desc: "We can adjust your formula if needed to make sure you’re feeling great.",
          after: "10 weeks after treatment",
        },
        {
          done: false,
          title: "Feel fantastic",
          desc: "Most women really start to feel great around this time. Now is a great time to talk to us if you want to dial-in your treatment",
          after: "10 weeks after treatment",
        },
      ],
      isUpsellModalVisible: false,
      blogs: [],
      coupon: {
        show: false,
        amount: "",
        message: "",
        showBlossomLink: false,
      },
      couponerror: {
        show: false,
        message: "",
      },
      followups: [],
    };
  },
  watch: {
    isUpsellModalVisible(val) {
      if (val === false) {
        this.fetchDashboardUpsell();
      }
    },
  },
  async mounted() {
    if (!localStorageService.getItem("accessToken")) {
      return this.$router.push("/login");
    }

    window.analytics.page();

    const blossom_promocodes = [
      "BFBLOSSOM40",
      "BFBLOSSOM40FIXED",
      "BLACKFRIDAY2022",
      "CYBERMONDAY2022",
      "BFBLOSSOM10",
    ];

    // this.getBlog();
    this.getuser();
    await this.fetchDashboardUpsell();

    // WIN-14968
    // Apply existing customer promocode from cookie
    if ($cookies.get("promocode") && $cookies.get("promocodeExistingID")) {
      try {
        const existingId = $cookies.get("promocodeExistingID");
        const res = await PromocodeService.applyExistingCustomerPromocode(
          existingId,
          $cookies.get("promocode")
        );
        if (res.status == 200) {
          const coupon = $cookies.get("promocode");
          this.coupon.message = `Coupon CODE: ${coupon} applied successfully! Coupon will reflect on your next prescription.`;
          this.coupon.show = true;
          this.$cookies.remove("promocode");
          this.$cookies.remove("promocodeExistingID");
        }
      } catch (err) {
        if (err.message) {
          this.couponerror.message = err.message;
        } else {
          this.couponerror.message =
            "This promocode is not applicable for this user.";
        }
        this.couponerror.show = true;
      }
    }

    // Check promocode for Blossom
    if (blossom_promocodes.includes($cookies.get("promocode"))) {
      const { data } = await OnboardingService.checkCoupon(
        $cookies.get("promocode")
      );
      this.coupon.show = true;
      this.coupon.message = "You have an active discount for 40% off Blossom.";
      this.coupon.amount = data.percent_off;
      this.coupon.showBlossomLink = true;
    }
  },
  methods: {
    getBlog: async () => {
      const query = `{
        journalCollection(limit:2, order:sys_publishedAt_ASC) {
          items {
            sys {
              id
            }
            title
            slug
            thumbnail {
              title
              url
            }
            thumbnailWebp {
              title
              url
            }
            category {
              sys {
                id
              }
              title
              slug
            }
          }
        }
      }`;
      const fetchUrl = `https://graphql.contentful.com/content/v1/spaces/${
        process.env.VUE_APP_CONTENTFUL_SPACE_ID
      }/environments/${
        process.env.VUE_APP_SERVER === "production" ? "main" : "staging"
      }`;
      const fetchOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query,
        }),
      };
      try {
        const response = await fetch(fetchUrl, fetchOptions).then((response) =>
          response.json()
        );
        return response.data.journalCollection.items;
      } catch (error) {
        throw new Error("Could not receive the data from Contentful!");
      }
    },
    showMore() {
      this.show = !this.show;

      if (!this.show) {
        window.scrollTo({ top: 0 });
      }
    },
    reloadPage() {
      window.location.reload();
    },
    getuser() {
      this.$store
        .dispatch("user/getUser")
        .then((data) => {
          this.$token = localStorageService.getItem("accessToken");
          if (data.onboardingstatus === "PENDING") {
            localStorageService.setItem("onboardingstep", data.onboardingstep);
          }
          this.id = data.id;
          if (data.firstname) {
            this.name = data.firstname;
          } else {
            this.name = data.email;
          }
          if (data.hasOwnProperty("identificationupload")) {
            this.iduploaded = data.identificationupload;
          }
          if (data.hasOwnProperty("photoupload")) {
            this.photouploaded = data.photoupload;
          }

          if (data.verificationstatus === undefined) {
            this.verificationstatus = true;
          } else {
            if (data.verificationstatus === "OK") {
              this.verificationstatus = true;
            } else {
              this.verificationstatus = false;
            }
          }
          if (data.checklist) {
            this.timeline = [];
            for (let step of data.checklist) {
              this.timeline[step.seqno - 1] = {
                done: step.checked,
                title: step.title,
                desc: step.description,
                after: step.remarks,
              };
            }
          }

          this.followups = data.followup;
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    async showBlossomModal(upsellcode) {
      await this.$store.dispatch("upsell/addUpsell", {
        product: upsellcode,
      });
      this.isUpsellModalVisible = true;
    },
    async fetchDashboardUpsell() {
      await this.$store.dispatch("upsell/fetchAvailableDashboardUpsell");
    },
    parseDate(unixDate) {
      const date = new Date(unixDate * 1000);
      return date.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "2-digit",
      });
    },
  },
  computed: {
    ...mapGetters("configuration", ["isUserSupportsAdminPatientChat"]),
    showSkipAlert() {
      return this.iduploaded == "SKIPPED" || this.photouploaded == "SKIPPED";
    },
    availableDashboardUpsell() {
      return this.$store.state.upsell.availableDashboardUpsell;
    },
    isTwoWeeksBeforeFollowup() {
      if (this.followups.length > 0) {
        const followupUnix = this.followups[0]?.followupdatestamp;
        return moment().isAfter(moment.unix(followupUnix).subtract(2, "week"));
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.verify-status {
  display: none;

  &.not-verify {
    display: block;
  }
}

.banner-box {
  background-color: #e9f5f3;
  min-height: 260px;
}

$size: 20px;

.timeline-box {
  padding: 2rem 2rem 0;
  background-color: #e9f5f3;
  overflow: hidden;

  @include media-breakpoint-up(xxl) {
    padding: 2rem * $scale-font 2rem * $scale-font;
  }
}

.timeline {
  margin-bottom: 3rem;
  overflow: hidden;

  @include media-breakpoint-up(xxl) {
    margin-bottom: 3rem $scale-font;
  }

  &.show-all {
    max-height: 100%;
  }

  &.hide-some {
    max-height: 360px;

    @include media-breakpoint-up(xl) {
      max-height: 310px;
    }

    @include media-breakpoint-down(sm) {
      max-height: 100%;
      margin-bottom: 0;
    }
  }

  li {
    position: relative;
    z-index: 1;
    display: flex;
    margin-bottom: 2rem;

    @include media-breakpoint-up(xxl) {
      margin-bottom: 2rem * $scale-font;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    &.in-progress,
    &.done {
      color: #333333;

      .b-icon.bi {
        fill: $primary;
      }
    }

    &.done {
      &:before {
        background-color: #cdfcb1;
      }

      &:after {
        border-color: #b1cac6;
        border-left-style: solid;
      }
    }

    .b-icon.bi {
      font-size: $size;
      position: absolute;

      @include media-breakpoint-up(xxl) {
        font-size: $size * $scale-font;
      }
    }

    &:before {
      display: inline-block;
      content: "";
      width: $size;
      height: $size;
      margin-right: 1.5rem;
      border-radius: $size;
      background-color: #b1cac6;
      flex: $size 0 0;

      @include media-breakpoint-up(xxl) {
        width: $size * $scale-font;
        height: $size * $scale-font;
        flex: $size * $scale-font 0 0;
      }
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: calc(#{$size} / 2 - 1px);
      z-index: -1;
      border-left: 2px solid #b1cac6;
      height: calc(100% + 2rem);

      @include media-breakpoint-up(xxl) {
        height: calc(100% + (2rem * #{$scale-font}));
        left: calc((#{$size} * #{$scale-font}) / 2 - 1px);
      }
    }
  }
}

.hero-banner {
  padding: 2.25rem;
  margin-top: 4.5rem;

  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }

  @include media-breakpoint-up(xxl) {
    padding: 2.25rem * $scale-font;
    margin-top: 4.5rem * $scale-font;
  }
}
</style>
