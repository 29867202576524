<template>
  <b-card body-class="c-card-body" :class="['c-card mb-5']" no-body>
    <b-row align-v="stretch" style="height: 100%;">
      <b-col cols="12" md="6" align-self="center" class="text-banner">
        <b-row align-v="stretch" style="height: 100%;" class="p-5">
          <b-col cols="12">
            <h1
              class="text-default display-3 mb-3 med-font"
              style="line-height: 1.1; font-weight: initial"
            >
              Hi <template v-if="!name">...</template
              ><template v-else>{{ name }}</template
              >, we’re so glad you’re here.
            </h1>
            <b-button pill variant="outline-dark" to="/treatment" size="lg">
              {{ buttonText }}
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        md="6"
        align-self="stretch"
        class="bg-img"
        :style="{
          backgroundImage: 'url(' + backgroundImage + ')',
          height: height,
          'margin-bottom': marginBottom,
        }"
      >
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "banner",
  props: {
    title: {
      type: String,
    },
    name: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    height: {
      type: String,
      default: "540px",
    },
    backgroundImage: {
      type: String,
    },
    buttonText: {
      type: String,
    },
    marginBottom: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-outline-dark {
  padding: 1.5rem 2.5rem;

  @include media-breakpoint-up(xxl) {
    padding: 1.5rem * $scale-font 2.5rem * $scale-font;
  }
}

.bg-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.c-card {
  min-height: 150px;
  border: 0 !important;
  margin: 2.5rem;
  background-color: #e9f5f3;

  @include media-breakpoint-up(xxl) {
    margin: 2.5rem * $scale-font;
  }

  @include media-breakpoint-down(sm) {
    margin: 1rem;
  }

  .c-card-body {
    display: flex;
  }

  .text-banner {
    padding: 6rem;

    @include media-breakpoint-down(sm) {
      padding: 0;
    }

    @include media-breakpoint-up(xxl) {
      padding: 6rem * $scale-font;
    }
  }

  img {
    width: 100%;
    height: 150px;
    object-fit: contain;
  }

  .img-box {
    height: 100px;
    width: 150px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
</style>
