<template>
  <div class="d-card">
    <div
      class="image-holder"
      :style="{
        backgroundImage: 'url(' + image + ')',
        height: imageHeight,
        backgroundColor: bgColor,
        '--borderColor': borderColor,
      }"
    ></div>
    <router-link :to="link" v-if="link" custom v-slot="{ navigate }">
      <a href="javascript:void(0)" @click="navigate" class="text-default">
        <h3 class="mb-0 heading">
          <span style="width: 70%; display: inline-block">{{ text }}</span>
          <span class="next-arrow"></span>
        </h3>
      </a>
    </router-link>

    <a :href="url" v-if="url" target="_blank" class="text-default">
      <h3 class="mb-0 heading">
        <span style="width: 70%; display: inline-block">
          {{ text }}
        </span>
        <span class="next-arrow"></span>
      </h3>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
    },
    imageHeight: {
      type: String,
      default: "385px",
    },
    link: {
      type: String,
      default: "",
    },
    url: {
      type: String,
    },
    text: {
      type: String,
    },
    bgColor: {
      type: String,
    },
    borderColor: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.d-card {
  position: relative;

  .image-holder {
    position: relative;
    background-size: cover;
    background-position: center;

    &:before {
      display: block;
      content: "";
      width: 100%;
      height: 11px;
      position: absolute;
      bottom: 0;
      background-color: var(--borderColor);
    }
  }

  .heading {
    display: flex;
    padding: 0.3em 0;
    min-height: 60px;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5em;
    line-height: 1.2;
  }
}
</style>
