import { BaseService } from "./base.service";
import { ErrorWrapper, ResponseWrapper } from "./util";

export class PromocodeService extends BaseService {
  static async applyExistingCustomerPromocode(patientid, promocode) {
    try {
      const response = await this.request({ auth: true }).post(
        `/discount/${patientid}/validate`,
        {
          promocode: promocode,
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
